import {Injectable,  signal} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../../environment/environment";
import {BehaviorSubject, Observable,  throwError } from 'rxjs';
import {ApiClient} from "../../api/api";
import {ToastrService} from "ngx-toastr";
import {catchError, tap} from "rxjs/operators";
import {User} from "../../../company/models/users.model";
import {Permission, Role } from '../../../company/models/role.model';
import {Router} from "@angular/router";

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private userSubject: BehaviorSubject<any> = new BehaviorSubject(null);
  private userSignal = signal<string | null>(null);
  private API_URL = environment.API_URL;
  authenticatedUser = signal<any>(null);
  private currentUser!: User;

  constructor(private http: HttpClient, private apiClient: ApiClient, private toastr: ToastrService, private router: Router) {}



  async login(login: string, password: string): Promise<any> {
    const body = { login, password };

    try {
      const response = await this.http.post<any>(`${this.API_URL}/api/auth/login`, body).toPromise();
      if (response.status === 'SUCCEED' && response.data && response.data.jwtToken) {
        const jwtToken = response.data.jwtToken;
        const message = response.message;
        const plateform = response.data.platform;
        localStorage.setItem('jwtToken', jwtToken);
        this.toastr.success(`${message}`, 'Success');
        return { plateform };
      } else {
        throw new Error('Login failed or JWT token not found');
      }
    } catch (error:any) {
      const errorMessage = error.error?.message || 'Une erreur est survenue lors de la connexion';
      const errorStatus = error.error?.status || 'Erreur';
      this.toastr.error(errorMessage, errorStatus);

      return Promise.reject(error);
    }
  }



  logout() {
    localStorage.removeItem('jwtToken');
    this.userSignal.set(null);
    this.clearUserCache()
  }

  loadUser(): Observable<any> {
    const token = localStorage.getItem('jwtToken');
    if (token) {
      if (!this.userSubject.value) {
        return this.apiClient.getAll<any>('api/auth/account/me').pipe(
          tap((user) => {
            this.currentUser = user.data;
            this.userSubject.next(user);
          }),
          catchError((error) => {
            this.toastr.error(error.error.message);
            if (error.error.status === '401') {
              this.logout();
              this.router.navigate(['/login']);
            }
            return throwError(error);
          })
        );
      }
    }
    return this.userSubject.asObservable();
  }


  clearUserCache() {
    this.userSubject.next(null);
  }

  getUser(): Observable<any> {
    return this.userSubject.asObservable();
  }



  getUserRole(): Role {
    return this.currentUser.role;
  }

  getUserPermissions(): Permission[] {
    return this.currentUser.role.permissions;
  }

  hasPermission(code: string): boolean {
    if (this.isAdmin("COMPANY_ADMIN") || this.isAdmin("BO_ADMIN") ){
      return true
    }else {
      return this.getUserPermissions().some((perm) => perm?.code === code && perm?.state === 'ACTIVE');
    }
  }

  hasRole(roleCode: string): boolean {
    const userRole = this.getUserRole();
    return userRole?.code === roleCode;
  }

  isAdmin(code: string): boolean {
    return this.hasRole(code);
  }


  setAuthenticatedUser(user: any) {
    this.authenticatedUser.set(user);
  }

  get user() {
    return this.userSignal();
  }
}
